<template>
  <CTabs variant="tabs" class="nav-tabs-boxed">
    <CTab title="Tags">
      <crud-base
        :url="url"
        :columns="columns"
        :onDelete="() => {}"
        :onUpdate="() => {}"
        :canDelete="(row) => row.chave === null"
        :canUpdate="(row) => row.chave === null"
        :onStore="() => {}"
        :onShow="() => {}"
        :onClone="(row) => { delete row['chave'] }"
        :enableSearch="true"
        :formInputs="formInputs"
        primaryKey="id"
      />
    </CTab>
  </CTabs>
</template>

<script>
import CrudBase from "../../components/crud/CrudBase";
import {yesNoRepresentation} from "@/helpers/transformers";
import {
  schemaBoolean,
  schemaSelect,
  schemaSqlEditor,
  schemaTextArea,
  schemaTextField
} from "@/helpers/form";
export default {
  name: "tag-filtro",
  components: {
    CrudBase,
  },
  data() {
    return {
      url: "/admin/tag-filtro",
      columns: [
        { label: "ID", field: "id", sortable: false },
        { label: "TITULO", field: "titulo", sortable: false },
        { label: "DESCRICAO", field: "descricao", sortable: false },
        { label: "ATIVO", field: "ativo", sortable: false, representedAs: (row) => yesNoRepresentation(row.ativo)},
        { label: "PERSONALIZADA", field: "chave", sortable: false, representedAs: (row) => row.chave !== null ? 'Não' : 'Sim'},
      ],
      formInputs: {
        titulo: schemaTextField('Título', true, 6),
        entidade: schemaSelect('Tipo de tag', true, 3, {
          "cliente" : "Cliente",
          "orcamento" : "Orçamento",
          "pedido" : "Pedido",
        }),
        ativo: schemaBoolean('Ativo', 1, 3, true),
        descricao: schemaTextArea('Descrição', true, 12),
        clausula: schemaSqlEditor('Cláusula SQL', true, 12),
      },
    };
  },
};
</script>
